.cookies {
  z-index: 1000;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background: $black;
  padding: 0 15px;
  color: $white;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;

  @media (max-width: 881px) {
    flex-direction: column;
    row-gap: 10px;
    padding: 15px;
  }
}

.cookies__extended-modal {
  z-index: 1002 !important;
}

.cookies__text-desktop {
  display: inline;

  @media (max-width: 881px) {
    display: none;
  }
}

.cookies__text-mobile {
  display: none;

  @media (max-width: 881px) {
    display: inline;
  }
}

.cookies__link {
  color: $black;

  &:hover {
    text-decoration: none;
  }
}

.cookies__buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 50px;
  margin: 0 auto;
  margin-top: 20px;

  @media (max-width: 800px) {
    width: 100%;
    padding-left: 0;
    justify-content: center;
  }

  @media (max-width: 500px) {
    margin-top: 10px !important;
    row-gap: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > :nth-child(1) { order: 1; }
    & > :nth-child(2) { order: 3; }
    & > :nth-child(3) { order: 2; }

    & > :nth-child(1),
    & > :nth-child(3) {
      flex: 1;
      text-align: center;
    }

    & > :nth-child(2) { width: 100%; }
  }
}

.cookies__button {
  color: $black;
  background: $primary;
  padding: 10px 30px;
  font-weight: 500;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.cookies__link {
  cursor: pointer;
  text-decoration: underline !important;

  &:hover {
    text-decoration: none !important;
  }
}

.cookies__table-wrapper {
  max-width: 100%;
  overflow: scroll;
}

.cookies__table {
  width: 100%;

  th {
    font-weight: 500;
    padding-right: 10px;
    border-bottom: 4px solid $black;
  }

  td {
    font-weight: 300;
    padding-right: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 14px;
    line-height: 21px;
    border-bottom: 1px solid $grayLightest;
    white-space: nowrap;
  }

  td:nth-child(1),
  td:nth-child(2) {
    white-space: nowrap;
  }

  td:last-child {
    padding-right: 0;
  }
}

.user-usage-info-modal {
  .paragraph {
    font-size: 15px;
    line-height: 1.5;
    font-weight: 500;
  }

  @media (max-width: 800px) {
    .small-modal {
      position: fixed;
      bottom: 0;
      min-height: initial;
    }

    .cookies__buttons {
      margin: 0;
    }

    .paragraph {
      font-size: 15px;
      line-height: 1.6;
    }
  }
}
