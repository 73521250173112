$jobMobileBreakpoint: 880px;

.job-container {
  @media (max-width: $jobMobileBreakpoint) {
    padding: 0;
    margin-top: 0;
  }
}

.job {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px dotted $black;
  color: $black;
  text-decoration: none;
  background: $white;

  @media (max-width: $jobMobileBreakpoint) {
    display: block;
  }
}

.job--admin-wrapper {
  align-items: initial !important;
}

.job--admin-warning {
  background: $red;
}

.job--admin-one-line {
  display: flex;
  flex: 1;
}

// cervena - caka na uverejnenie a uhradu
.job--no-payment {
  background: $red;
}

// zelena - uhradena a caka na uverejnenie
.job--no-published-admin {
  background: $greenPrimaryRgba;
}

// job klientsky - oranzove
.job--no-published {
  background: $primaryRgba;
}

.job--draft-admin,
.job--draft {
  background: $grayLightest;
}

// oranzova - neuverejneny social
.job--social-action {
  background: $primaryRgba;
}

// modra - talentbox zmenit
.job--talentbox-action {
  background: rgba(15, 10, 222, 0.2);
}

.job--banned {
  opacity: 0.3;
}

.job:not(.job--company-profile) {
  overflow: hidden;
}

@media (min-width: 701px) {
  /* stylelint-disable-next-line */
  .job:not(.job--company-profile):not(.job--loading):not(.job--preview):not(.job--admin):hover:not(.job--invoice):not(.job--custom-paid):not(.job--custom-paid--greenfox):not(.job--custom-paid--pretovolim) {
    cursor: pointer;
    background: $graySuperLight !important;

    .pill--tag {
      background: $white;

      &.pill--dark-hoverable {
        background: $black;
      }
    }

    .pill--tag.pill--link:hover {
      background: $black !important;

      &.pill--dark-hoverable {
        background: $white !important;
        color: #777;
      }
    }
  }
}

@media (max-width: $jobMobileBreakpoint) {
  .job:not(.job--company-profile):not(.job--loading):active {
    cursor: pointer;

    .pill--tag.pill--link:active {
      background: $black;
    }
  }
}

.container:last-child .job,
.job--no-border-bottom {
  border-bottom: none;
}

.job__logo-wrapper,
.job__content-right,
.job__content-left,
.job__content-admin {
  @media (max-width: $jobMobileBreakpoint) {
    display: block;
  }
}

.job__content-right-wrapper {
  @media (min-width: 900px) {
    display: flex;
    margin-left: auto;
  }
}

.job__content-left {
  flex-grow: 1;
}

.job__content-right {
  width: 240px;

  @media (max-width: $jobMobileBreakpoint) {
    display: block;
    width: 100%;
    margin-top: 10px;
  }

  @media (max-width: $mobileBreakpoint) {
    margin-top: 0;
  }
}

.job__content-right--wide {
  width: 350px;
}

.job__content-action {
  width: 50px;
  text-align: right;
}

.job__content-date {
  width: 105px;
  text-align: right;

  .pill {
    margin-top: 5px;
    margin-bottom: 0;
    margin-right: 0;
  }

  @media (max-width: $jobMobileBreakpoint) {
    width: 100%;
    text-align: left;

    .pill {
      margin-top: 10px;
      margin-right: 5px;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    .pill {
      margin-top: 5px;
      margin-right: 5px;
    }
  }
}

.job__content-admin {
  width: 300px;
  text-align: right;

  @media (max-width: $jobMobileBreakpoint) {
    display: block;
    width: 100%;
    margin-top: 10px;
  }
}

.job__content-admin--small {
  width: 200px;
}

.job__logo-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 80px;
  min-width: 80px;
  text-align: center;
  margin-right: 24px;
  align-self: flex-start;

  @media (max-width: $jobMobileBreakpoint) {
    display: block;
    margin-bottom: 10px;
    margin-right: 20px;
    text-align: left;
    width: 100%;
    max-width: 60px;
    min-width: 60px;
    float: left;
  }
}

.job__logo-wrapper--bigger {
  width: 100px;

  @media (max-width: $jobMobileBreakpoint) {
    display: block;
    margin-bottom: 10px;
    margin-right: 20px;
    text-align: left;
    width: 60px;
    float: left;
  }
}

.job__emoji-wrapper {
  display: flex;
  align-items: center;
  font-size: 30px;
  text-align: center;
  margin-right: 25px;
}

.job__logo {
  position: relative;
  width: 100%;
  height: auto;

  @media (max-width: $jobMobileBreakpoint) {
    top: 1px;
  }
}

.job__logo--rounded {
  border-radius: 50%;
}

.job__title {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  color: $grayLight;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  span:first-child {
    margin-right: 6px;
  }
}

.job__subtitle {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  min-height: 24px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.job__subtitle.job__subtitle--bold {
  font-family: "Rubik", sans-serif;
  font-weight: 900;
  font-size: 23px;
  color: $black;
  text-transform: uppercase;
}

.job__info {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.job__info--hide-mobile {
  @media (max-width: 700px) {
    display: none;
  }
}

.job__info--loading {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 80px;
  height: 15px;
  background: $grayLightest;
}

.job__info--light {
  font-weight: 300;
  color: $grayLight;
}

.job__info--green {
  color: $green;
}

.job__info--orange {
  color: $primary;
}

.job__info--gray {
  color: $grayDark;
}

.job__info a {
  color: $black;

  &:hover {
    text-decoration: none;
  }
}

.job__info-desktop {
  @media (max-width: $jobMobileBreakpoint) {
    display: none;
  }
}

.job__info-mobile {
  display: none;

  @media (max-width: $jobMobileBreakpoint) {
    display: block;
  }
}

.job__info--margin-top {
  margin-top: 20px;

  @media (max-width: $mobileBreakpoint) {
    margin-top: 10px;
  }
}

.job--with-logo {
  .job__content-left,
  .job__content-right,
  .job__content-date {
    @media (max-width: $jobMobileBreakpoint) {
      padding-left: 80px;
    }
  }
}

.job__content-date {
  @media (max-width: $jobMobileBreakpoint) {
    br {
      display: none;
    }
  }
}

.job.job--level-3 {
  background: $yellow;
}

.job--admin {
  align-items: normal;
  justify-content: space-between;

  .job__content-left {
    flex: 0 0 350px;
  }
}

.job--admin:hover {
  cursor: default;
}

.job__info-link {
  color: $primary;

  &:hover {
    text-decoration: none;
  }
}

.job__info-link.job__info-link--with-icon {
  text-decoration: none !important;

  span {
    text-decoration: underline;
  }

  &:hover {
    span {
      text-decoration: none;
    }
  }
}

.job__info-link.job__info-link--profile {
  color: $black;
  margin-right: 13px;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

.job--payment {
  border-bottom: none;

  &:hover {
    background: transparent !important;
    cursor: default !important;
  }

  .job__logo-wrapper {
    margin-top: -4px;
  }

  .job__content-right,
  .job__content-left {
    text-align: left;
  }
}

.job--company-profile {
  flex-wrap: wrap;
  min-height: 114px;
}

.job--offer {
  min-height: 112px;
  background: $grayLightest;
  margin-bottom: 15px;
  border-bottom: none;

  @media (max-width: $jobMobileBreakpoint) {
    margin-left: 30px;
    margin-right: 30px;
  }

  @media (max-width: $mobileBreakpoint) {
    margin-left: 24px;
    margin-right: 24px;
  }

  .profile__job-right {
    flex: 1;

    @media (max-width: 1160px) {
      margin-top: 0;
      text-align: right;
    }

    @media (max-width: 800px) {
      width: 100%;
      flex: none;
      text-align: left;
    }
  }
}

.job--invoice {
  display: block;
  background: $graySuperLight;
  margin-bottom: 15px;
  border-bottom: none;
  cursor: none;
  min-height: 143px;

  &:hover {
    cursor: initial !important;
    background: $graySuperLight !important;
  }

  .profile__candidate-name {
    padding-left: 0;
  }
}

.job--invoice.job--invoice-red {
  background: $primaryRgba !important;

  &:hover {
    background: $primaryRgba !important;
  }
}

.job--invoice-content {
  display: flex;
  align-items: center;
  align-items: baseline;

  @media (max-width: $jobMobileBreakpoint) {
    flex-wrap: wrap;

    .profile__grid-item {
      flex: 0 0 50%;
      text-align: left;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    .profile__grid-item {
      flex: 0 0 100%;
    }
  }
}

.job--offer-black {
  background: $black;
  color: $white;

  .job__info-link,
  .job__subtitle--bold {
    color: $white;
  }

  .job__info--light,
  .job__title {
    color: #888;
  }
}

.job__content-right.job__content-right--fix-admin {
  width: 200px;
  flex: 0 0 200px;
  padding-right: 10px;
}

.job--company-profile.job--bg-top {
  background: $yellow;
}

.job__title .pill--super-small {
  position: relative;
  top: -1px;
  margin-right: 6px;
}

.job-add-badge {
  position: absolute;
  top: 0;
  right: -180px;
  transform: rotate(-330deg);
  color: $black;
  font-family: "Rubik", sans-serif;
  font-size: 21px;
  font-weight: 900;
  text-transform: uppercase;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: $primary;
  animation: rotate 6s infinite linear;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1220px) {
    display: none;
  }
}

.job-cv-button {
  margin: 0 auto;

  @media (max-width: $mobileBreakpoint) {
    max-width: none;
  }
}

.job__info .pill--super-small {
  position: relative;
  top: -1px;
}

.job__info .pill--super-small-blank {
  position: relative;
  top: 0;
}

.job.job--custom-paid {
  background: url("/images/paid/isadore/dres.png") no-repeat #f0e9e3;
  background-size: auto 130px;
  background-position: top 0 right 170px;

  .job__title,
  .job__subtitle {
    color: $black !important;
  }

  .pill {
    background: $black !important;
    color: $white !important;
  }

  @media (max-width: 1100px) {
    background-position: top 0 right 140px;
  }

  @media (max-width: 1050px) {
    background: #f0e9e3;
  }

  .job__logo:not(.job__logo--hover) {
    display: block;
  }

  .job__logo.job__logo--hover {
    display: none;
  }

  &:hover {
    .job__logo:not(.job__logo--hover) {
      display: none !important;
    }

    .job__logo.job__logo--hover {
      display: block;
    }
  }
}

.job.job--custom-paid--greenfox {
  background: url("/images/paid/greenfox-6-23/bg@2x.png") no-repeat $black !important;
  background-size: 100% auto !important;
  background-position: top right;

  @media (max-width: 940px) {
    background-size: auto 100% !important;
    background-position: top left;
  }

  &:hover {
    .job__title,
    .job__subtitle {
      color: $white;
    }
  }
}

.job.job--custom-paid--pretovolim {
  /* stylelint-disable-next-line color-hex-case */
  background: url("/images/paid/pretovolim/jobpost@2x.png") #611FC7;
  background-size: 401px 110px;
  background-repeat: no-repeat;
  background-position: center;
  height: 140px;

  @media (max-width: 500px) {
    background-size: 229px 60px;
    height: 80px;
  }
}

.job.job--custom-paid--redbull {
  background: url("/images/paid/redbull/frame@2x.png"), url("/images/paid/redbull/octopus@2x.png"), url("/images/paid/redbull/spaceship@2x.png"), url("/images/paid/redbull/cloud@2x.png");
  background-size: 1140px 17px, 111px 79px, 115px 113px, 140px 73px;
  background-repeat: repeat, no-repeat, no-repeat, no-repeat;
  background-position: 0 0, bottom -10px right -10px, top 0 right 380px, bottom -10px right 550px;

  .job__title,
  .job__subtitle {
    color: $black;
  }

  .pill {
    background: $black;
    color: $white;
  }

  .pill--white {
    background-color: $white;
    color: $black;
  }

  @media (max-width: 1050px) {
    background: url("/images/paid/redbull/frame@2x.png"), url("/images/paid/redbull/octopus@2x.png");
    background-size: 1140px 17px, 111px 79px;
    background-repeat: repeat, no-repeat;
    background-position: 0 0, bottom -10px right -10px;
  }

  @media (min-width: 701px) {
    &:hover {
      background-color: #222c78;

      .job__title,
      .job__subtitle {
        color: $white;
      }

      .job__info,
      .job__info--light {
        color: $white;
      }

      .pill {
        background: $white !important;
        color: $grayLight !important;
      }
    }
  }
}

.job-preview {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: $white;
  border-top: 1px dotted $black;

  @media (max-width: 880px) {
    display: none;
  }

  @media (max-height: 630px) {
    display: none;
  }
}

.job-preview--yellow {
  background: $yellow;
}

.job.job--preview {
  padding: 5px 0 !important;
  min-height: 93px !important;

  .job__logo {
    top: 0;
  }

  .pills-wrapper {
    margin-top: 4px;
  }
}

.job__info--padding-left {
  padding-left: 37px;
}

.job__info-button {
  @media (max-width: $jobMobileBreakpoint) {
    margin-top: 10px;
    width: 100%;
  }
}

.job__info-link--profile-more.dropdown__handler {
  padding-right: 20px;

  &::after {
    right: 0;
  }
}

.job--dark-bg-variant:not(:hover) {
  color: $white;

  .job__title,
  .job__info--light {
    color: $white;
  }
}

@media (max-width: $jobMobileBreakpoint) {
  .job--dark-bg-variant {
    color: $white;

    .job__title,
    .job__info--light {
      color: $white;
    }
  }
}

.job-detail--dark-bg-variant {
  color: $white;

  .job__title,
  .job-detail__heading-1,
  .job-detail__heading-1 a,
  .job-detail__heading-2,
  .job__info--light {
    color: $white;
  }

  .tooltip__button {
    background: url("/images/icons/tooltip-white.svg");
  }
}

.job--admin-row {
  display: flex;
  margin-top: 8px;
  column-gap: 60px;
}

.job__sponsored {
  text-transform: uppercase;
  background: $white;
  color: $black;
  font-size: 12px;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.job__sponsored-cta {
  width: 186px;
  height: 60px;
  background: url("/images/paid/vacuum/button@2x.png");
  background-size: cover;

  @media (max-width: 880px) {
    display: none;
  }
}

.job__sponsored-cta--greenfox {
  width: 186px;
  height: 60px;
  background: url("/images/paid/greenfox/button@2x.png");
  background-size: cover;

  @media (max-width: 880px) {
    display: none;
  }
}

.job-addon-old-price-label {
  text-decoration: line-through;
  color: $grayLight;
  font-weight: 300;
}

.job-packages-login-banner {
  background: $primary;

  .job-packages-login-banner-inner {
    padding: 20px;
  }

  .job-packages-login-banner-pill {
    color: $white;
    background: $black;
    display: inline-block;
    line-height: 1;
    padding: 0 4px;
  }

  .job-packages-login-banner-sub-heading {
    font-size: 68px;
    z-index: 1;
    align-self: flex-start;

    &::before {
      content: "";
      background: $white;
      position: absolute;
      left: 10px;
      right: 14px;
      z-index: -1;
      bottom: 16px;
      height: 20px;
    }

    @media (max-width: 500px) {
      font-size: 52px;

      &::before {
        left: 10px;
        height: 14px;
      }
    }
  }
}

.job-preview-modal {
  padding: 60px;

  .small-modal {
    max-width: 100%;
    min-height: 100%;
  }

  .modal__header-close .button__in {
    color: $grayLightest;
    background: $black;
  }
}

.job-preview-preview-cta-pill {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: $jobMobileBreakpoint) {
    right: -5px;
  }
}
