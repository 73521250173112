.margin-top-big {
  margin-top: 150px;
}

.margin-top {
  margin-top: 60px;

  @media (max-width: $mobileBreakpoint) {
    margin-top: 40px;
  }
}

.margin-top-small {
  margin-top: 30px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-super-small {
  margin-top: 10px;
}

.margin-top-tiny {
  margin-top: 6px;
}

.no-margin-top {
  margin-top: 0 !important;
}

.margin-bottom {
  margin-bottom: 60px;

  @media (max-width: $mobileBreakpoint) {
    margin-bottom: 40px;
  }
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-small {
  margin-bottom: 30px;
}

.margin-bottom-super-small {
  margin-bottom: 10px !important;
}

.margin-bottom-tiny {
  margin-bottom: 5px !important;
}

.margin-bottom-100 {
  margin-bottom: 100px;
}

.margin-bottom-big {
  margin-bottom: 150px;

  @media (max-width: 700px) {
    margin-bottom: 70px;
  }
}

.no-padding {
  padding: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.float-left {
  float: left;
}

.center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.text-limit {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.padding-15 {
  padding: 15px !important;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-bottom-small {
  padding-bottom: 30px;
}

.padding-bottom-80-important {
  padding-bottom: 80px !important;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.no-select {
  user-select: none;
}

.sticky {
  position: sticky;
  top: 0;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-justify-center {
  justify-content: center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-items-center {
  align-items: center;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.clickable {
  user-select: none;
  cursor: pointer;
}

.clickable-link {
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
}

.border-bottom {
  border-bottom: 1px dotted $black;
}

.nbsp {
  white-space: nowrap;
}

.width-full {
  width: 100% !important;
}

.max-width-full {
  max-width: 100%;
}

.bg--orange {
  background-color: $primaryRgba !important;
}

.nowrap {
  white-space: nowrap !important;
}
