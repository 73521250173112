.dropdown {
  display: inline-block;
}

.dropdown__content {
  display: none;
  position: absolute;
}

.dropdown--active .dropdown__content {
  display: block;
  z-index: 101;
}

.dropdown__handler {
  position: relative;
  margin-right: 20px !important;

  &::after {
    position: absolute;
    top: 10px;
    right: -20px;
    content: "";
    background: url("/images/icons/icon-filter-black.svg") no-repeat;
    background-size: contain;
    font-size: 10px;
    margin-left: 5px;
    width: 10px;
    height: 10px;
  }
}

.dropdown__inner {
  position: relative;
  z-index: 100;
  background: $grayLightest;
  padding: 20px 25px;
  text-align: left;
  border: 1px dotted $black;

  a {
    word-break: normal !important;
  }
}

.dropdown__inner-single {
  padding: 15px 20px;

  &:first-child {
    border-bottom: none;
  }

  &:nth-child(3) {
    border-top: none;
  }
}

.dropdown__item {
  display: block;
  line-height: 30px;
  margin-right: 0 !important;
}

.header__company {
  position: relative;

  .dropdown__content {
    position: absolute;
    top: 60px;
    right: 21px;

    @media (max-width: $headerTo) {
      top: 0;
      right: auto;
      left: 0;
    }
  }

  .dropdown__inner {
    width: 225px;

    .link {
      display: inline-block;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .dropdown__language {
    width: auto;
    font-size: 16px;
    font-weight: 500;

    @media (max-width: $mobileBreakpoint) {
      font-size: 15px;
    }

    .dropdown__language-link {
      color: #000;
      text-transform: uppercase;

      &:hover {
        cursor: pointer;

        .dropdown__language-link-label {
          text-decoration: none;
        }
      }

      .dropdown__language-link-label {
        text-decoration: underline;
      }
    }
  }

  .dropdown__handler-white {
    position: relative;
    margin-right: 20px;

    &::after {
      position: absolute;
      top: 50%;
      margin-top: -3px;
      right: 0;
      content: "";
      background: url("/images/icons/icon-filter.svg") no-repeat;
      background-size: contain;
      font-size: 10px;
      width: 10px;
      height: 10px;
    }
  }
}

.dropdown__handler-black {
  position: relative;
  margin-right: 20px;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 0;
    content: "";
    background: url("/images/icons/icon-filter-black.svg") no-repeat;
    background-size: contain;
    font-size: 10px;
    width: 10px;
    height: 10px;
  }

  &:hover {
    .header__company-link {
      text-decoration: none;
    }
  }
}

.dropdown .upsell-line {
  padding: 10px 16px;
  padding-top: 8px;
  margin: -4px -8px;
  margin-bottom: 10px;
  width: calc(100% + 16px);
}
